



























import { defineComponent, getCurrentInstance } from '@vue/composition-api'

import CloseIcon from './icons/CloseIcon.vue'
import SearchIcon from './icons/SearchIcon.vue'

import { customSearchInputProps, useSearchInput } from './SearchInput.hooks'
import { SearchInputProps } from './SearchInput.contracts'

/**
 * Component capable to render Input element.
 *
 * @displayName SearchInput
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const SearchInput = defineComponent({
  name: 'CustomSearchInput',
  components: {
    CloseIcon,
    SearchIcon
  },
  props: customSearchInputProps,

  setup (props: SearchInputProps) {
    return useSearchInput(props, getCurrentInstance())
  }
})
export default SearchInput
